<template>
  <div
      ref="el"
      class="mentionable"
      :class="$attrs.class"
      style="position:relative;"
  >
    <slot />

    <v-card
        v-bind="{ ...$attrs, class: undefined }"
        v-if="!!currentKey"
        :triggers="[]"
        class="pa-0"
        elevation="2"
        style="position:absolute; z-index: 999"
        :style="caretPosition ? {
        top: `${caretPosition.top + 30}px`,
        left: `${caretPosition.left + 10}px`,
      } : {}"
    >
      <v-card-text class="pa-0">
        <div v-if="!displayedItems.length">
          <slot name="no-result">
            No result
          </slot>
        </div>

        <v-list dense v-else max-height="200" class="overflow-y-auto">
          <v-list-item
              v-for="(item, index) of displayedItems"
              :key="index"
              class="mention-item"
              :id="`list-item-${index}`"
              @click=""
              :class="{
              'active': selectedIndex === index,
            }"
              @mousedown="applyMention(index)"
          >
            <slot
                :name="`item-${currentKey || oldKey}`"
                :item="item"
                :index="index"
            >
              <slot
                  name="item"
                  :item="item"
                  :index="index"
              >
                {{ item.label || item.value || item.name }}
              </slot>
            </slot>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import getCaretPosition from 'textarea-caret'

export default {
  props: {
    keys: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      default: () => [],
    },

    omitKey: {
      type: Boolean,
      default: false,
    },

    filteringDisabled: {
      type: Boolean,
      default: false,
    },

    insertSpace: {
      type: Boolean,
      default: false,
    },

    mapInsert: {
      type: Function,
      default: null,
    },

    limit: {
      type: Number,
      default: 8,
    },

    theme: {
      type: String,
      default: 'mentionable',
    },

    caretHeight: {
      type: Number,
      default: 0,
    },
  },

  emits: ['search', 'open', 'close', 'apply'],

  data: () => ({
    currentKey: "",
    currentKeyIndex: 0,
    oldKey: "",
    searchText: "",
    selectedIndex: 0,
    input: null,
    cancelKeyUp: null,
    lastSearchText: null,
    caretPosition: { top: 0, left: 0, height: 0 }
  }),
  computed: {
    filteredItems() {
      if (!this.searchText || this.filteringDisabled) {
        return this.items
      }

      const finalSearchText = this.searchText.toLowerCase()

      return this.items.filter(item => {
        let text
        if (item.searchText) {
          text = item.searchText
        } else if (item.label) {
          text = item.label
        } else {
          text = ''
          for (const key in item) {
            text += item[key]
          }
        }
        return text.toLowerCase().includes(finalSearchText)
      })
    },
    displayedItems() {
      return this.filteredItems.slice(0, this.limit)
    }
  },
  watch: {
    searchText(value, oldValue) {
      if (value) {
        this.$emit('search', value, oldValue)
      }
    },
    displayedItems() {
      this.selectedIndex = 0
    },
  },
  methods: {
    getInput() {
      return this.$refs.el.querySelector('input') ?? this.$refs.el.querySelector('textarea') ?? this.$refs.el.querySelector('[contenteditable="true"]')
    },
    attach () {
      if (this.input) {
        this.input.addEventListener('input', this.onInput)
        this.input.addEventListener('keydown', this.onKeyDown)
        this.input.addEventListener('keyup', this.onKeyUp)
        this.input.addEventListener('scroll', this.onScroll)
        this.input.addEventListener('blur', this.onBlur)
      }
    },
    detach () {
      if (this.input) {
        this.input.removeEventListener('input', this.onInput)
        this.input.removeEventListener('keydown', this.onKeyDown)
        this.input.removeEventListener('keyup', this.onKeyUp)
        this.input.removeEventListener('scroll', this.onScroll)
        this.input.removeEventListener('blur', this.onBlur)
      }
    },
    onInput() {
      this.checkKey()
    },
    onBlur() {
      this.closeMenu()
    },
    onKeyDown(e) {
      if (this.currentKey) {
        if (e.key === 'ArrowDown') {
          this.selectedIndex++
          if (this.selectedIndex >= this.displayedItems.length) {
            this.selectedIndex = 0
          }
          const el = document.getElementById(`list-item-${this.selectedIndex}`)
          el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
          this.cancelEvent(e)
        }
        if (e.key === 'ArrowUp') {
          this.selectedIndex--
          if (this.selectedIndex < 0) {
            this.selectedIndex = this.displayedItems.length - 1
          }
          const el = document.getElementById(`list-item-${this.selectedIndex}`)
          el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
          this.cancelEvent(e)
        }
        if ((e.key === 'Enter' || e.key === 'Tab') &&
            this.displayedItems.length > 0) {
          this.applyMention(this.selectedIndex)
          this.cancelEvent(e)
        }
        if (e.key === 'Escape') {
          this.closeMenu()
          this.cancelEvent(e)
        }
      }
    },
    onKeyUp (e) {
      if (this.cancelKeyUp && e.key === this.cancelKeyUp) {
        this.cancelEvent(e)
      }
      this.cancelKeyUp = null
    },
    cancelEvent (e) {
      e.preventDefault()
      e.stopPropagation()
      this.cancelKeyUp = e.key
    },
    onScroll() {
      this.updateCaretPosition()
    },
    getSelectionStart () {
      return this.input.isContentEditable ? window.getSelection().anchorOffset : this.input.selectionStart
    },
    setCaretPosition (index) {
      this.$nextTick(() => {
        this.input.selectionEnd = index
      })
    },
    getValue () {
      return this.input.isContentEditable ? window.getSelection().anchorNode.textContent : this.input.value
    },
    setValue (value) {
      this.input.value = value
      this.emitInputEvent('input')
    },
    emitInputEvent (type) {
      this.input.dispatchEvent(new Event(type))
    },
    checkKey () {
      const index = this.getSelectionStart()
      if (index >= 0) {
        const { key, keyIndex } = this.getLastKeyBeforeCaret(index)
        const text = this.lastSearchText = this.getLastSearchText(index, keyIndex)
        if (!(keyIndex < 1 || /\s/.test(this.getValue()[keyIndex - 1]))) {
          return false
        }
        if (text != null) {
          this.openMenu(key, keyIndex)
          this.searchText = text
          return true
        }
      }
      this.closeMenu()
      return false
    },
    getLastKeyBeforeCaret (caretIndex) {
      const [keyData] = this.keys.map(key => ({
        key,
        keyIndex: this.getValue().lastIndexOf(key, caretIndex - 1),
      })).sort((a, b) => b.keyIndex - a.keyIndex)
      return keyData
    },
    getLastSearchText (caretIndex, keyIndex) {
      if (keyIndex !== -1) {
        const text = this.getValue().substring(keyIndex + 1, caretIndex)
        // If there is a space we close the menu
        if (!/\s/.test(text)) {
          return text
        }
      }
      return null
    },
    updateCaretPosition () {
      if (this.currentKey) {
        if (this.input.isContentEditable) {
          const rect = window.getSelection().getRangeAt(0).getBoundingClientRect()
          const inputRect = this.input.getBoundingClientRect()
          this.caretPosition = {
            left: rect.left - inputRect.left,
            top: rect.top - inputRect.top,
            height: rect.height,
          }
        } else {
          this.caretPosition = getCaretPosition(this.input, this.currentKeyIndex)
        }
        this.caretPosition.top -= this.input.scrollTop
        if (this.caretHeight) {
          this.caretPosition.height = this.caretHeight
        } else if (isNaN(this.caretPosition.height)) {
          this.caretPosition.height = 16
        }
      }
    },
    openMenu (key, keyIndex) {
      if (this.currentKey !== key) {
        this.currentKey = key
        this.currentKeyIndex = keyIndex
        this.updateCaretPosition()
        this.selectedIndex = 0
        this.$emit('open', this.currentKey)
      }
    },
    closeMenu () {
      if (this.currentKey != null) {
        this.oldKey = this.currentKey
        this.currentKey = null
        this.$emit('close', this.oldKey)
      }
    },
    applyMention (itemIndex) {
      const item = this.displayedItems[itemIndex]
      const value = (this.omitKey ? '' : this.currentKey) + String(this.mapInsert ? this.mapInsert(item, this.currentKey) : item.name) + (this.insertSpace ? ' ' : '')
      if (this.input.isContentEditable) {
        const range = window.getSelection().getRangeAt(0)
        range.setStart(range.startContainer, range.startOffset - this.currentKey.length - (this.lastSearchText ? this.lastSearchText.length : 0))
        range.deleteContents()
        range.insertNode(document.createTextNode(value))
        range.setStart(range.endContainer, range.endOffset)
        this.emitInputEvent('input')
      } else {
        this.setValue(this.replaceText(this.getValue(), this.searchText, value, this.currentKeyIndex))
        this.setCaretPosition(this.currentKeyIndex + value.length)
      }
      this.$emit('apply', item, this.currentKey, value)
      this.closeMenu()
    },
    replaceText (text, searchString, newText, index) {
      return text.slice(0, index) + newText + text.slice(index + searchString.length + 1, text.length)
    }
  },
  mounted() {
    this.input = this.getInput()
    this.attach()
  },
  updated() {
    const newInput = this.getInput()
    if (newInput !== this.input) {
      this.detach()
      this.input = newInput
      this.attach()
    }
  },
  destroyed() {
    this.detach()
  },
}
</script>


<style scoped>

.active {
  background-color: rgba(128, 128, 128, 0.5);
}

.userMentioned {
  text-decoration: underline !important;
}

#dropDown {
  position: absolute;
  z-index: 1000;
  top: v-bind(topPosition);
  left: v-bind(leftPosition);
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid darkgray;
  border-top: none;
}
</style>
