<template>
  <TasksAutocomplete :items="items" :keys="['@']">
    <v-textarea
        outlined
        v-model="text"
        :label="label"
        rows="3"
        filled
        hide-details
    />
    <template #no-result>
      <div class="dim">
        <span class="px-4">No users found</span>
      </div>
    </template>

    <template #item-@="{ item }">
      <div class="user">
        {{ item.name }}
      </div>
    </template>
  </TasksAutocomplete>
</template>

<script>
import TasksAutocomplete from "./TasksAutocomplete.vue";

export default {
  name: 'Mentionable',
  components: {TasksAutocomplete},
  props: {
    items: {
      type: Array,
      required: true
    },
    input: {
      required: true,
    },
    label: {
      type: String
    }
  },
  data: () => ({
    text: "",
    mentionedUsers: {}
  }),
  watch: {
    text(val) {
      this.$emit('update:input', val)
      this.items.forEach(item => {
        if (val.includes(`@${item.name}`)) {
          this.$set(this.mentionedUsers, item.id, true)
        }
        else {
          this.$delete(this.mentionedUsers, item.id)
        }
      })
    },
    mentionedUsers: {
      handler(val) {
        this.$emit('onMention', val)
      },
      deep: true
    }
  }

}
</script>
