<template>
  <v-hover v-slot="{ hover }">
    <v-card style="cursor: pointer;" :style="[allSubtasksClosed ? {'background-color': '#8A8A8A'} : '']" :elevation="hover ? 8 : 3"  :loading="loading" rounded outlined>
      <v-card-title class="d-flex justify-space-between" >
        <div  style="overflow: hidden;
        text-overflow: ellipsis;white-space: nowrap;display: inline-block">
          <slot name="title" >
            <span :class="{'white--text' : allSubtasksClosed}">{{task.title}}</span>
            <v-icon v-if="allSubtasksClosed" style="color: #4CAF50;font-size: 30px">mdi-check-circle</v-icon>
          </slot>
        </div>
        <v-chip small color="warning" v-if="task.new">UNSAVED</v-chip>
        <v-btn @click="removeUnsavedTask(index)" v-if="task.new" icon><v-icon color="black">mdi-delete</v-icon></v-btn>
        <v-menu transition="scroll-y-transition" offset-y left>
<!--          <template #activator="{on, attrs}">-->
<!--            <v-btn-->
<!--                :loading="loading"-->
<!--                v-if="!task.new"-->
<!--                color="black"-->
<!--                icon-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--            >-->
<!--              <v-icon>mdi-dots-vertical</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <slot name="actions"/>-->
        </v-menu>
      </v-card-title>
      <v-card-text />
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'ClientTask',
  props: {
    task: {
      type: Object,
      required: true
    },
    index: {
      required: true
    },
    loading: {
      required: true,
      default: false
    }
  },
  computed:{
    allSubtasksClosed(){
      if (!this.task.sub_tasks || !this.task.sub_tasks.length) return  false;
      return this.task.sub_tasks.every(subtask => subtask.closed);
    }
  },
  methods: {
    removeUnsavedTask(index) {
      this.$emit('removeUnsavedTask', index)
    },
  }
}
</script>

<style scoped>
.selected {
  background-color: #E7F4FF
}
</style>
