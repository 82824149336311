<template>
  <div>
    <ClientHeader :client="client" />
    <v-row>
      <v-col   v-if="roleId === 1 || roleId === 13" cols="12">
        <v-btn :small="isMobile" @click="createNewTask" color="primary" rounded>
          <v-icon left>mdi-plus</v-icon>
          Create New
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" md="4"><v-skeleton-loader type="card" /></v-col>
      <v-col cols="12" md="8"><v-skeleton-loader type="card" /></v-col>
    </v-row>
    <v-row v-else-if="!loading && tasks.length" class="fill-height">
      <v-col
        v-if="selectedTaskIdx === null || !isMobile"
        cols="12"
        md="4"
        class="d-flex flex-column"
        style="gap: 10px"
      >
        <div @click="selectTask(i)" v-for="(task, i) in tasks" :key="task.id">
          <ClientTask
            :class="{ selected: selectedTaskIdx === i }"
            :task="task"
            :index="i"
            :loading="loadingTask[i]"
            @removeUnsavedTask="handleRemoveTask"
          >
            <template #actions>
              <v-list dense>
                <v-list-item @click="editTaskName(task.id)">Edit Name</v-list-item>
              </v-list>
            </template>
          </ClientTask>
        </div>
      </v-col>
      <v-col class="d-flex align-center d-md-none" v-if="tasks[selectedTaskIdx]">
        <v-btn @click="selectedTaskIdx = null" icon>
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">Main Task {{ selectedTaskIdx + 1 }}</span>
      </v-col>
      <v-col cols="12" md="8" v-if="tasks[selectedTaskIdx]">
        <v-skeleton-loader v-if="taskDetailsLoading" type="card" />
        <ClientTaskDetails
          v-else
          :usersToMention="usersToMention"
          :usersToAssign="usersToAssign"
          :files="files"
          :index="selectedTaskIdx"
          @updateTasks="getTasks"
          @taskCreated="handleNewTask"
          @subtaskCreated="handleNewSubtask"
          @subtaskClosed="getTasks"
          :task="taskDetails ?? tasks[selectedTaskIdx]"
          :roleId="roleId"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="4">
        <v-alert
          colored-border
          elevation="2"
          type="info"
        >
          There are no tasks at this moment!
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientTask from "@/components/Client/ClientTask.vue";
import ClientTaskDetails from "@/components/Client/ClientTaskDetails.vue";
import ClientService from "@/services/clients.service";
import TasksService from "@/services/tasks.service";
import {mapGetters} from "vuex";
import AuthService from "../../services/auth.service";
import UserService from "@/services/user.service";

export default {
  name: "Tasks",
  components: { ClientTaskDetails, ClientTask, ClientHeader },
  data: () => ({
    clientId: "",
    client: null,
    loading: false,
    tasks: [],
    selectedTaskIdx: null,
    taskDetails: null,
    taskDetailsLoading: false,
    loadingTask: [],
    usersToMention: [],
    usersToAssign: [],
    files: [],
    roleId: null,
    user: null
  }),
  methods: {
    getCurrentClient() {
      return ClientService.getOneClient(this.clientId).then((res) => {
        this.client = res;
      });
    },
    getTasks() {
      this.loading = true;
      TasksService.getClientTasks(this.clientId).then((res) => {
        this.tasks = res;
        const selectedTaskId = this.$route.hash.split('#')[1]

        if (selectedTaskId) {
          const indexOf = this.tasks.findIndex((task) => task.id === +selectedTaskId)
          this.selectedTaskIdx = indexOf ?? null
          this.loadTaskDetails()
        }else {
          this.selectedTaskIdx = 0
          this.loadTaskDetails()
        }
      }).finally(() => {
        this.loading = false;
        return true
      });
    },
    handleNewTask(task, index) {
      this.handleRemoveTask(index)
      this.tasks.push(task)
      this.selectTask(this.tasks.length - 1)
    },
    handleNewSubtask() {
      this.selectTask(this.selectedTaskIdx)
    },
    handleRemoveTask(index) {
      this.tasks.splice(index, 1)
    },
    getDocumentTypes(){
      ClientService.getUploadReqDocs(this.clientId).then((res) => {
        this.files = res;
        this.files.push({document:null,document_name:'Filedrop'})
      })
    },
    getUsersToMention() {
      ClientService.getUsersToMention(this.clientId).then(res => {
        this.usersToMention = res
      })
    },
    async getUsersToAssign() {
      const { managing_firm, attorney_of_record } = this.client
      let users;
      if (this.user.role_id === 1){
        users = await ClientService.getEntitiesFiltered('my')
        this.usersToMention.forEach(user => {
          if (user.role === 13){
            users.users.push(user)
          }
        })
      }else {
         users = await ClientService.getEntityById(managing_firm.id)
      }
      let additionalUsers = [];
      if (this.user.role_id === 1){
        users.users.forEach(user => {
          if (user.role_id === 4 || user.role_id === 1 || user.role_id === 13 || user.role === 13){
            additionalUsers.push(user)
          }
        })
      }else {
        additionalUsers = users.filter(user => user.role_id === 4)
      }

      const attorneyName = `${attorney_of_record.first} ${attorney_of_record.last}`
      this.usersToAssign = [
          {id: attorney_of_record.id, name: attorneyName},
          ...additionalUsers.map(e => { return {id: e.id, name: e.name} }),
          {id: null, name: 'Patriot Staff'}
      ]
      // if (this.roleId === 1)
      //   this.usersToAssign.pop()
    },
    async getCurrentUserRole() {
      const { data } = await AuthService.getCurrentUser(this.currentUser.current_user_id)
      this.roleId = data.role_id
    },
    selectTask(i) {
      this.selectedTaskIdx = i;
      this.taskDetails = null
      this.loadTaskDetails()

      if (this.isMobile) window.scroll(0, 0);
    },
    loadTaskDetails() {
      if (!this.tasks[this.selectedTaskIdx]) return
      if (this.tasks[this.selectedTaskIdx].new) return

      this.taskDetailsLoading = true

      TasksService.getTaskDetails(this.tasks[this.selectedTaskIdx].id).then(data => {
        this.taskDetails = data
      }).finally(() => this.taskDetailsLoading = false)
    },
    async getUser() {
      const data = await AuthService.getCurrentUserFromCookie()
      this.user = data.data
    },
    createNewTask() {
      this.taskDetails = null
      this.tasks.unshift({
        new: true,
        title: "New Task"
      })
      this.selectedTaskIdx = this.isMobile ? null : 0;
    },
  },
  async mounted() {
    this.getUser()
    this.loading = true;
    this.clientId = this.$route.params.clientId;
    await this.getCurrentClient();
    await this.getCurrentUserRole()
    await this.getUsersToMention()
    this.getDocumentTypes()
    this.getUsersToAssign();
    await this.getTasks();
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
};
</script>
